<template>
  <div class="index">
    <ul class="menu">
      <li @click="openMenu('account')">我的资料</li>
      <li @click="openMenu('accountTruthName')">实名认证</li>
      <!-- <li @click="openMenu('accountNews')">消息中心</li> -->
      <li @click="openMenu('accountAddress')">我的地址</li>
      <li @click="openMenu('accountOrder')">我的订单</li>
      <li @click="openMenu('accountCart')">我的购物车</li>
      <li @click="openMenu('accountTuijian')">我的推荐</li>
    </ul>
    <div class="main">
      <div class="title">实名认证</div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        label-position="left"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="13">
            <el-form-item label="真实姓名：" prop="realName">
              <el-input v-model="ruleForm.realName"></el-input>
            </el-form-item>
            <el-form-item label="身份证号：" prop="cardId">
              <el-input v-model="ruleForm.cardId"></el-input>
            </el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  prop="cardValidityDateStart"
                  label="身份证有效期:"
                >
                  <el-date-picker
                    type="date"
                    placeholder="开始有效期"
                    v-model="ruleForm.cardValidityDateStart"
                    value-format="yyyy-MM-dd"
                    style="width: 135px"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  prop="cardValidityDateEnd"
                  label="至"
                  label-width="40px"
                >
                  <el-date-picker
                    type="date"
                    placeholder="结束有效期"
                    value-format="yyyy-MM-dd"
                    v-model="ruleForm.cardValidityDateEnd"
                    style="width: 135px"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="性别：" prop="gender">
              <el-select v-model="ruleForm.gender" placeholder="请选择">
                <el-option :label="`男`" :value="`0`"></el-option>
                <el-option :label="`女`" :value="`1`"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择地区:" prop="role">
              <el-cascader
                v-if="status == 4"
                v-model="value"
                ref="cascader"
                :options="AreaJson"
                :props="{
                  expandTrigger: 'hover',
                  value: 'cityId',
                  label: 'name',
                  children: 'child',
                }"
              ></el-cascader>
              <p v-else>
                {{ ruleForm.cardProvince }} - {{ ruleForm.cardCity }}
              </p>
            </el-form-item>
            <el-form-item label="详细地址：" prop="cardAddress">
              <el-input
                type="text"
                v-model="ruleForm.cardAddress"
                placeholder="请输入身份证上的地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="银行卡开户行：" prop="bank">
              <el-input
                type="text"
                v-model="ruleForm.bank"
                placeholder="请具体到银行卡开户支行"
              ></el-input>
            </el-form-item>
            <el-form-item label="银行卡号：" prop="bankCardNumber">
              <el-input
                type="text"
                v-model="ruleForm.bankCardNumber"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="9" :offset="1">
            <el-form-item prop="cardPositiveImage">
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                :http-request="aliyunUpload"
              >
                <img
                  v-if="ruleForm.cardPositiveImage"
                  :src="ruleForm.cardPositiveImage"
                  class="avatar"
                />
                <span v-else
                  ><i class="el-icon-plus avatar-uploader-icon"></i
                  >请上传身份证正面图片</span
                >
              </el-upload>
            </el-form-item>
            <el-form-item prop="cardNegativeImage">
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                :http-request="aliyunUploadId"
              >
                <img
                  v-if="ruleForm.cardNegativeImage"
                  :src="ruleForm.cardNegativeImage"
                  class="avatar"
                />
                <span v-else
                  ><i class="el-icon-plus avatar-uploader-icon"></i
                  >请上传身份证反面图片</span
                >
              </el-upload>
            </el-form-item>

            <el-form-item prop="bankCardPositiveImage">
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                :http-request="aliyunUploadBank"
              >
                <img
                  v-if="ruleForm.bankCardPositiveImage"
                  :src="ruleForm.bankCardPositiveImage"
                  class="avatar"
                />
                <span v-else
                  ><i class="el-icon-plus avatar-uploader-icon"></i
                  >请上传银行卡正面图片</span
                >
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <el-button
            type="primary"
            v-if="status == 4"
            @click="submitForm('ruleForm')"
            >提交实名认证</el-button
          >
          <el-button type="warning" v-if="status == 0">等待审核</el-button>
          <el-button type="success" v-if="status == 1"
            >已完成实名认证</el-button
          >
          <el-button
            type="primary"
            v-if="status == -1"
            @click="submitForm('ruleForm')"
            >已驳回请重新提交申请</el-button
          >
          <el-button v-if="!status == 1" @click="resetForm('ruleForm')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <div class="tipsBox">
        <p class="titles">温馨提示</p>
        <p>1、银行卡用于提现，必须是本人名下的借记卡</p>
        <p>2、身份证需要和银行借记卡是同一人名下的</p>
        <p>3、同一身份证只能注册一个账户，未完成实名认证的账户不可提现</p>
      </div>
    </div>
  </div>
</template>
<script>
import { AreaJson } from "./area.js";

import { apiTruthName } from "@/api/account";
import axios from "axios";
export default {
  name: "basePAge",
  data() {
    return {
      AreaJson,
      value: "",
      status: "4",
      ruleForm: {
        bank: "",
        bankCardNumber: "",
        bankCardPositiveImage: "",
        cardAddress: "",
        cardCity: "",
        cardNegativeImage: "",
        cardPositiveImage: "",
        cardProvince: "",
        cardValidityDateEnd: "",
        cardValidityDateStart: "",
        cardId: "",
        realName: "",
        gender: "",
      },
      rules: {
        bank: [
          { required: true, message: "请输入借记卡开户行", trigger: "blur" },
        ],
        bankCardNumber: [
          { required: true, message: "请输入银行借记卡卡号", trigger: "blur" },
        ],
        bankCardPositiveImage: [
          { required: true, message: "请上传银行卡正面图片", trigger: "blur" },
        ],
        cardAddress: [
          {
            required: true,
            message: "请输入身份证上详细地址",
            trigger: "blur",
          },
        ],
        cardCity: [
          { required: true, message: "请输入身份证省市", trigger: "blur" },
        ],
        cardNegativeImage: [
          { required: true, message: "请上传身份证反面", trigger: "blur" },
        ],
        cardPositiveImage: [
          { required: true, message: "请上传身份证正面", trigger: "blur" },
        ],
        cardProvince: [
          { required: true, message: "请输入身份证省市", trigger: "blur" },
        ],
        cardValidityDateEnd: [
          { required: true, message: "身份证截止有效期", trigger: "blur" },
        ],
        cardValidityDateStart: [
          { required: true, message: "身份证起始有效期", trigger: "blur" },
        ],
        cardId: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
        ],
        realName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    value() {
      let obj = this.$refs["cascader"].getCheckedNodes()[0];
      this.ruleForm.cardProvince = obj.pathLabels[0];
      this.ruleForm.cardCity = obj.pathLabels[1];
      this.ruleForm.address.district = obj.pathLabels[2];
      this.ruleForm.cityId = obj.value;
    },
  },
  mounted() {
    this.getTruthStatus();
  },
  methods: {
    getTruthStatus() {
      this.https.get("/api/front/user/realCheckStatus").then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          let obj = res.data.data;
          this.status = obj.status;
          // this.value =
          this.ruleForm = {
            bank: obj.bank,
            bankCardNumber: obj.bankCardNumber,
            bankCardPositiveImage: obj.bankCardPositiveImage,
            cardAddress: obj.cardAddress,
            cardCity: obj.cardCity,
            cardNegativeImage: obj.cardNegativeImage,
            cardPositiveImage: obj.cardPositiveImage,
            cardProvince: obj.cardProvince,
            cardValidityDateEnd: obj.cardValidityDateEnd,
            cardValidityDateStart: obj.cardValidityDateStart,
            cardId: obj.cardId,
            realName: obj.realName,
            gender: obj.gender + "",
          };
        }
      });
    },
    openMenu(index) {
      localStorage.setItem("accountIndex", "accountTruthName");

      this.$router.push({ name: index });
    },
    aliyunUpload(params) {
      let ossData = new FormData();
      ossData.append("multipart", params.file);
      ossData.append("model", "user");
      ossData.append("pid", 7);
      axios.post("/api/front/user/upload/image", ossData).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.ruleForm.cardPositiveImage = res.data.data.url;
        }
      });
    },
    aliyunUploadId(params) {
      let ossData = new FormData();
      ossData.append("multipart", params.file);
      ossData.append("model", "user");
      ossData.append("pid", 7);
      axios.post("/api/front/user/upload/image", ossData).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.ruleForm.cardNegativeImage = res.data.data.url;
        }
      });
    },
    aliyunUploadBank(params) {
      let ossData = new FormData();
      ossData.append("multipart", params.file);
      ossData.append("model", "user");
      ossData.append("pid", 7);
      axios.post("/api/front/user/upload/image", ossData).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.ruleForm.bankCardPositiveImage = res.data.data.url;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          apiTruthName(this.ruleForm).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.$message.success(res.message);
            } else {
              this.$message.warning(res.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.index {
  width: 1200px;
  margin: 0 auto;
  background-color: #f5f6f7;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  .menu {
    width: 200px;
    background-color: #fff;
    li {
      line-height: 60px;
      border-bottom: 1px solid #ccc;
      font-size: 16px;
    }
  }
  .main {
    width: 980px;
    background-color: #fff;
    padding: 15px 20px;
    .title {
      line-height: 50px;
      text-align: left;
      font-size: 16px;
      border-bottom: 1px dashed blue;
      margin-bottom: 20px;
    }
  }
}
::v-deep .el-form-item__content {
  width: 250px;
  text-align: left;
}

.avatar-uploader .el-upload {
  width: 100%;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
::v-deep.el-upload--text {
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 150px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  text-align: center;
}
.avatar {
  width: 240px;
  height: 150px;
  display: block;
  border: 1px solid #ccc;
}
.tipsBox {
  margin-top: 50px;
  text-align: left;
  p {
    line-height: 36px;
    font-size: 14px;
    color: #999;
    padding-left: 20px;
  }
  .titles {
    font-size: 16px;
    color: #666;
    padding-left: 0;
    line-height: 50px;
  }
}
</style>